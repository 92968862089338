import {
    CalculationHistoryType,
    CurrencyDto,
    EffectCategoryHistoryEntryDto,
    EffectField,
    EffectHistoryEntryDto,
    EffectType,
    GenerationHistoryEntryDto,
    HistoryEventType,
} from "api-shared";
import { TFunction } from "i18next";
import { camelCase } from "lodash";
import moment, { MomentInput } from "moment";
import { CurrencyFormatter } from "../hooks/useCurrency";
import { DateTimeFormatter } from "../hooks/useTimezone";

interface HistoryDto {
    attribute: any;
    newValue: string | number | null;
}

export function isId(value: number) {
    return Number.isInteger(+value);
}

export function isMatching(targetValue: string | null | undefined, value: string | null) {
    return targetValue === undefined || targetValue === value;
}

export function attributeName({ attribute }: HistoryDto, translate: TFunction) {
    if (attribute == null || isId(+attribute)) {
        // dont try to translate ids
        return "";
    }
    // use camelCase here because of start_date and end_date
    return attribute.length > 0 ? translate([attribute, camelCase(attribute)]) : "";
}

export function formatCalculationValue(
    value: string | number | null,
    formatDate: DateTimeFormatter,
    formatCurrency: CurrencyFormatter,
    customCurrency?: CurrencyDto | string,
) {
    if (value == null) {
        return "";
    }
    if (Number.isNaN(+value)) {
        return formatDate(value as MomentInput);
    }
    return formatCurrency(value, customCurrency);
}

export function captureType(value: string | null, effectType: EffectType, translate: TFunction) {
    if (value === null) {
        return "";
    }
    if (value === "0") {
        return translate(`effect_type_${effectType}`);
    }
    return `${translate(EffectField.Initial)}/${translate(EffectField.Target)}`;
}

export function effectDate(item: EffectHistoryEntryDto) {
    const calendarMoment = moment().month(item.month).year(item.year);
    return calendarMoment.format("MMM YYYY");
}

export function getEffectType(historyEntries: (EffectHistoryEntryDto | EffectCategoryHistoryEntryDto | GenerationHistoryEntryDto)[]) {
    const effectTypeEntry = historyEntries.find((entry) => {
        if (entry.historyType !== CalculationHistoryType.EffectCategory) {
            return false;
        }
        return entry.attribute === "effect_type" && entry.operationType === HistoryEventType.INSERT;
    });
    return effectTypeEntry !== undefined ? (effectTypeEntry.newValue as EffectType) : EffectType.Savings;
}

export const replaceImage = (value: string): string => {
    const base64regex = /!\[.*\]\(data:([A-Za-z-+/]+);base64,(.+)\)/g;
    if (value && base64regex.test(value)) {
        return value.replaceAll(base64regex, "🖼");
    }
    return value;
};
