import {
    CostLeverDto,
    EffectCategoryAttributeDto,
    EffectCategoryDto,
    FormatableUser,
    formatUserName,
    IFormatUserOptions,
    SYSTEM_USER_ID,
    TranslateFunction,
    TreeNodeDto,
    UNKNOWN_USER,
    UserStatus,
} from "api-shared";
import { TFunction } from "i18next";
import { Moment } from "moment";
import { defaultLanguage, Language, translationKeys } from "../translations/main-translations";
import { TranslatedOption } from "./field-options";
import { mapConstantsToTranslations, USER_STATUS_FIELD_NAME } from "./fields";
import { translateFromProperty } from "./translate";

/**
 * Format as percentage.
 *
 * @export
 * @param {number} value value (not fractional), e.g. 42 will be 42.00%
 * @param {Language} [language=defaultLanguage]
 * @returns {string}
 */
export function withPercentage(value: number, language: Language = defaultLanguage, precision = 2): string {
    if (value == null) {
        return "";
    }
    const float = parseFloat(value.toFixed(precision));

    const formatted = float.toLocaleString(language, { minimumFractionDigits: precision });

    return `${formatted}%`;
}

/**
 * Convert first character of a string to uppercase
 *
 * @export
 * @param {string} input
 * @returns {string}
 */
export function firstCharToUppercase(input: string): string {
    return input.replace(/(.)/, (match: string) => match.charAt(0).toUpperCase());
}

/**
 * Format a size in human readable format (supports units B/kB/MB/GB/TB).
 *
 * @export
 * @param {number} size in bytes
 * @returns {string}
 */
export function formatSize(size: number): string {
    const unitIndex = size > 0 ? Math.floor(Math.log(size) / Math.log(1024)) : 0;
    const units = ["B", "kB", "MB", "GB", "TB"];
    const prefix = unitIndex > 0 ? (size / Math.pow(1024, unitIndex)).toFixed(2) : size;
    return `${prefix} ${units[unitIndex]}`;
}

class FormatUserOptions implements IFormatUserOptions {
    /**
     *Creates an instance of FormatUserOptions.
     * @param {TFunction} translate
     * @param {boolean} [withAdornment=true]
     * @memberof FormatUserOptions
     */
    constructor(
        public translate?: TFunction,
        public withAdornment = true,
        public warnOnMissingUser = true,
    ) {}
}

/**
 * Resolve userId inside the given users array and format the user with given options.
 *
 * Does also check for SYSTEM_USER_ID and may return UNKOWN_USER when user cannot be resolved and is not the SYSTEM_USER.
 *
 * @export
 * @param {number} userId
 * @param {User[]} users
 * @param {IFormatUserOptions} options
 * @returns {string}
 */
export function formatUserFromId(userId: number, users: User[], options: IFormatUserOptions): string {
    if (userId === SYSTEM_USER_ID) {
        const { translate } = options;
        const key = translationKeys.VDLANG_SYSTEM_USER;
        return translate != null ? translate(key) : key;
    }

    if (userId != null && users != null) {
        // TODO remove this check, once all components are migrated
        const foundUser = users.find((user) => user.id === userId);
        // when user cannot be resolved, let still the formatUser function decide how to format "unknown user"
        return formatUser(foundUser ?? null, options);
    }

    if (options.warnOnMissingUser) {
        // eslint-disable-next-line no-console
        console.warn("Cannot resolve user", userId);
    }

    return UNKNOWN_USER;
}

/**
 * Format a list of user as names. Concatenates the user names with ", ".
 *
 * @see formatUserFromId and @see formatUser
 *
 * @export
 * @param {number[]} userIds
 * @param {User[]} users
 * @param {IFormatUserOptions} options
 * @returns {string}
 */
export function formatUsersFromIds(userIds: number[], users: User[], options: IFormatUserOptions): string {
    return userIds.map((id) => formatUserFromId(id, users, options)).join(", ");
}

// Minimal interface for objects that can be formatted as user. Some additional properties are needed for contributor badge and short
// formatting
interface User extends FormatableUser {
    id: number;
    realname: string | null;
}

/**
 * Format a user with according to options.
 * @export
 * @param {(User | null)} [user]
 * @param {IFormatUserOptions} [options]
 * @returns {string}
 */
export function formatUser(user: User | null, options?: IFormatUserOptions): string {
    const defaultOptions = new FormatUserOptions();
    const { translate, withAdornment } = { ...defaultOptions, ...options };

    const name = formatUserName(user, options);
    if (user == null) {
        return name;
    }

    if (!withAdornment || typeof translate !== "function") {
        return name;
    }

    const { status } = user;
    const adornment = status !== undefined ? getStatusAdornment(status, translate) : null;
    return adornment != null ? `${name} (${adornment})` : name;
}

/**
 * Get adornment text about the user status, e.g. "deactivated".
 *
 * @export
 * @param {UserStatus} status
 * @param {TFunction} translate
 * @returns {(string | null)} status text or null, if status does not have any
 */
export function getStatusAdornment(status: UserStatus, translate: TFunction | TranslateFunction): string | null {
    const statusesToDisplay = [UserStatus.STATUS_INACTIVE, UserStatus.STATUS_INVITED];
    if (typeof translate === "function" && statusesToDisplay.includes(status)) {
        const key = mapConstantsToTranslations(USER_STATUS_FIELD_NAME, status);
        return key !== null ? translate(key) : null;
    }
    return null;
}

/**
 * Format user in short version, outputs first letters of expected first- and lastname from real name. E.g. DD for "Donald Duck"
 *
 * @export
 * @param {User} user
 * @returns {string} short variant of users name or empty string if realname is not set
 */
export function formatUserShort(user: User): string {
    if (user == null) {
        return "";
    }

    const displayOrRealname = user.displayname ?? user.realname;
    if (displayOrRealname == null || displayOrRealname.length === 0) {
        return "";
    }

    const nameFragments = displayOrRealname.split(/\s/).filter((element) => {
        return element.length !== 0;
    });

    if (nameFragments.length > 1) {
        return `${nameFragments[0][0]}${nameFragments[nameFragments.length - 1][0]}`;
    }
    return `${nameFragments[0][0]}`;
}

/**
 * Format moments into a date time string that can be send to our API.
 * Format: YYYY-MM-DD HH:mm:ss
 * @export
 * @param {Moment} date
 * @returns {string}
 */
export function formatDateTimeForApi(date: Moment): string {
    return date.format("YYYY-MM-DD HH:mm:ss");
}

/**
 * Format a method according to given language. Displays the code in front of the method name.
 *
 * @export
 * @param {CostLeverDto} method
 * @param {Language} language
 * @returns {string}
 */
export function formatMethod(method: CostLeverDto, language: Language): string {
    return `${method.code} ${translateFromProperty(method, "name", language)}`;
}

export function formatTreeNode(treeNode: TreeNodeDto, language: Language): string {
    return translateFromProperty(treeNode, "name", language);
}

export function formatEffectCategory(
    fields: EffectCategoryAttributeDto[],
    fieldData: TranslatedOption[][],
    effectCategory: EffectCategoryDto,
): string {
    const fieldLabels = fields.map((attribute, index) => {
        const value = effectCategory.effectCategoryValues.find(
            ({ effectCategoryAttributeId }) => effectCategoryAttributeId === attribute.id,
        );
        const option = fieldData[index].find(({ id }) => id === value?.value);
        return option?.name ?? "";
    });
    return fieldLabels.filter((name) => name != null).join("/");
}

// use formatter with en locale, as 1000 will not be shortened in de locale in compact display
const formatter = Intl.NumberFormat("en", { notation: "compact" });

/**
 * Format an interval. Usually [intervalUpperBoundaries[intervalSelectionIndex - 1], intervalUpperBoundaries[intervalSelectionIndex]]. First and last interval are open.
 * Example:
 * intervalUpperBoundaries: [10, 100, 1000]
 * intervalSelectionIndex: 0 -> <10
 * intervalSelectionIndex: 1 -> >10–100
 * intervalSelectionIndex: 2 -> >100–1000
 * intervalSelectionIndex: 3 -> >1000
 *
 * Compact notation aligns with the "compact" option of Intl.NumberFormat, e.g. 1000 -> 1K, 1000000 -> 1M, 1000000000 -> 1B
 *
 * @export
 * @param {number[]} intervalUpperBoundaries upper boundaries of the intervals to select from
 * @param {number} intervalSelectionIndex index in intervalUpperBoundaries to select, may be one large to indicate in open interval starting at the last element in intervalEnds
 * @returns
 */
export function formatRangeCompact(intervalUpperBoundaries: number[], intervalSelectionIndex: number) {
    const clampedIndex =
        intervalSelectionIndex >= intervalUpperBoundaries.length ? intervalUpperBoundaries.length - 1 : intervalSelectionIndex;
    const upperBoundary = formatter.format(intervalUpperBoundaries[clampedIndex]);

    if (intervalSelectionIndex === 0) {
        // If the first interval just spans zero, don't show a < sign, but just 0
        if (upperBoundary === "0") {
            return "0";
        }

        return `<${upperBoundary}`;
    }

    if (intervalSelectionIndex === intervalUpperBoundaries.length) {
        return `>${upperBoundary}`;
    }

    // With NodeJS 20, this may be adapted to use Intl.NumberFormat.prototype.formatRange function, which event supports currencies
    const lowerBoundary = formatter.format(intervalUpperBoundaries[clampedIndex - 1]);
    return `>${lowerBoundary}–${upperBoundary}`;
}
