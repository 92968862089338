// import only the font-weights used by MUI
// https://material-ui.com/components/typography/#install-with-npm
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Store } from "redux";
import ThemeSelector from "./components/ThemeSelector";
import LoadingAnimation from "./components/loading/LoadingAnimation";
import { HubspotTrackingCode } from "./infrastructure/hubspot";
import { MatomoRouterProvider } from "./infrastructure/tracking";
import { environment } from "./lib/environment";
import MainContainer from "./view/MainContainer";

interface IAppProps {
    queryClient: QueryClient;
    store: Store;
}

const App = ({ queryClient, store }: IAppProps) => {
    useEffect(() => {
        // Manually set document title, store&translations are not available yet
        document.title = "Valuedesk";
    }, []);

    // Some react-router APIs require a data router, which would require knowledge about all routes here
    // We don't want that, so we use a dummy data router, which also supports defining routes further down the component tree
    const router = createBrowserRouter([
        {
            path: "/*",
            element: (
                <MatomoRouterProvider>
                    <HubspotTrackingCode hubId={environment.hubspotTrackingCodeHubId}>
                        <ThemeSelector>
                            <Suspense fallback={<LoadingAnimation />}>
                                <MainContainer />
                            </Suspense>
                        </ThemeSelector>
                    </HubspotTrackingCode>
                </MatomoRouterProvider>
            ),
        },
    ]);

    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                {!environment.isProduction && <ReactQueryDevtools />}
                <RouterProvider router={router} />
            </QueryClientProvider>
        </Provider>
    );
};

export default App;
