import { EstimatesValue } from "api-shared";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentClient } from "../../../domain/client";
import useCurrency from "../../../hooks/useCurrency";
import { useLanguage } from "../../../hooks/useLanguage";
import { Language, translationKeys } from "../../../translations/main-translations";

export interface IUseIdeaEstimateConversion {
    potentialEstimateValue: number | null;
    timeEstimateValue?: number | null;
    effortEstimateValue?: number | null;
}

export type IdeaEstimate = {
    value: number;
    label: string;
};

export type IdeaEstimates = {
    potentialEstimates: IdeaEstimate[];
    timeEstimates: IdeaEstimate[];
    effortEstimates: IdeaEstimate[];
};

const formatter = Intl.NumberFormat("en", { notation: "compact" });

const formatPotential = (potentialIntervals: number[], index: number) => {
    if (index === 0) {
        return `<${formatter.format(potentialIntervals[0])}`;
    }
    if (index === 4) {
        return `>${formatter.format(potentialIntervals[3])}`;
    }
    return `${formatter.format(potentialIntervals[index - 1])}–${formatter.format(potentialIntervals[index])}`;
};

export const useIdeaEstimates = (): IdeaEstimates => {
    const { t: translate } = useTranslation();
    const { ideaPotentialIntervals } = useCurrentClient();

    const potentialEstimates = useMemo(
        () =>
            Object.values(EstimatesValue)
                .filter((key) => typeof key === "number" && key > 0)
                .map((key, index) => {
                    return { value: Number(key), label: formatPotential(ideaPotentialIntervals, index) };
                }),
        [ideaPotentialIntervals],
    );

    const timeEstimates = useMemo(
        () =>
            Object.values(EstimatesValue)
                .filter((key) => typeof key === "number" && key > 0)
                .map((key) => {
                    return { value: Number(key), label: translate(`${translationKeys.VDLANG_IDEAS_TIME_ESTIMATE_SLIDER_VALUE}.${key}`) };
                }),
        [translate],
    );

    const effortEstimates = useMemo(
        () =>
            Object.values(EstimatesValue)
                .filter((key) => typeof key === "number" && key > 0)
                .map((key) => {
                    return { value: Number(key), label: translate(`${translationKeys.VDLANG_IDEAS_EFFORT_ESTIMATE_SLIDER_VALUE}.${key}`) };
                }),
        [translate],
    );

    return { potentialEstimates, timeEstimates, effortEstimates };
};

export const useIdeaEstimateConversion = () => {
    const { t: translate } = useTranslation();
    const { currencyIsoCode } = useCurrency();
    const { ideaPotentialIntervals } = useCurrentClient();
    const language = useLanguage();

    const formatCurrencyForLanguage = useCallback(
        (estimateValue: number) => {
            const formattedPotential = formatPotential(ideaPotentialIntervals, estimateValue / 10 - 1);
            if (language === Language.DE) {
                return `${formattedPotential} ${currencyIsoCode}`;
            }
            return `${currencyIsoCode} ${formattedPotential}`;
        },
        [currencyIsoCode, ideaPotentialIntervals, language],
    );

    return ({ potentialEstimateValue, timeEstimateValue, effortEstimateValue }: IUseIdeaEstimateConversion) => {
        // potentialEstimateValue / 10 - 1 maps enum value 10,20,30... to array index 0,1,2...
        const potentialValue =
            potentialEstimateValue != null
                ? formatCurrencyForLanguage(potentialEstimateValue)
                : translate(translationKeys.VDLANG_IDEAS_FIELDS_NOT_SET);

        const timeValue =
            timeEstimateValue != null
                ? translate(`${translationKeys.VDLANG_IDEAS_TIME_ESTIMATE_SLIDER_VALUE}.${timeEstimateValue}`) +
                  " " +
                  translate(translationKeys.VDLANG_IDEAS_MONTH_LABEL)
                : translate(translationKeys.VDLANG_IDEAS_FIELDS_NOT_SET);

        const effortValue =
            effortEstimateValue != null
                ? translate(`${translationKeys.VDLANG_IDEAS_EFFORT_ESTIMATE_SLIDER_VALUE}.${effortEstimateValue}`)
                : translate(translationKeys.VDLANG_IDEAS_FIELDS_NOT_SET);

        return { potentialValue, timeValue, effortValue };
    };
};
