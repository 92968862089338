import TrendingFlatIcon from "@mui/icons-material/TrendingFlatRounded";
import { Button, Grid, styled, TextField, TextFieldProps } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateValidationError } from "@mui/x-date-pickers/internals";
import { FirstDate, formatDateForAPI, isDateBetween, LastDate } from "api-shared";
import { TFunction } from "i18next";
import moment, { Moment } from "moment";
import { useRef, useState } from "react";
import useDialog from "../../../hooks/useDialog";
import useForkedRef from "../../../hooks/useForkedRef";
import { translationKeys } from "../../../translations/main-translations";

interface IDateRangePickerProps {
    value: IDaterange | null;
    updateValue: (newValue: { start?: string | null; end?: string | null }) => void; // used
    translate: TFunction;
    showError?: boolean;
    disabled?: boolean;
}

export interface IDaterange {
    start: string | null;
    end: string | null;
}

function formatDateRangeForAPI(start: moment.Moment | null, end: moment.Moment | null): IDaterange {
    return {
        start: formatDateForAPI(start),
        end: formatDateForAPI(end),
    };
}

const getHelperText = (value: Moment | null, error: DateValidationError, translate: TFunction) => {
    const validRange = value !== null ? isDateBetween(value) : true;

    if (!validRange || error === "maxDate") {
        return translate(translationKeys.VDLANG_DATERANGE_PICKER_OUTSIDE_SCOPE);
    }

    if (error === "minDate") {
        return translate(translationKeys.VDLANG_DATERANGE_PICKER_END_BEFORE_START);
    }

    if (error === "invalidDate") {
        return translate("Invalid date");
    }

    return "";
};

const CustomTextField = ({
    inputRef,
    otherInputRef,
    ...textFieldProps
}: TextFieldProps & { otherInputRef: TextFieldProps["inputRef"] }) => {
    const combinedRef = useForkedRef(inputRef, otherInputRef);
    return <TextField {...textFieldProps} inputRef={combinedRef} />;
};

const PresetButton = styled(Button)(({ theme }) => ({
    ...theme.typography.body2, // align text with input text
    flexShrink: 0,
}));

const usePicker = () => {
    const dialog = useDialog();
    const [error, setError] = useState<DateValidationError>(null);
    const inputRef = useRef<HTMLInputElement>();

    const onTextFieldClick = () => {
        // open dialog when clicking the text input, but still keep focus inside of the input element
        dialog.open();
        setTimeout(() => {
            // Opening the dialog will automatically trap focus there
            // To enable proper keyboard input, focus needs to be moved back after the dialog has opened
            if (inputRef.current != null && document.contains(inputRef.current) && document.activeElement !== inputRef.current) {
                inputRef.current.focus();
            }
        }, 500);
    };

    const onClose = () => {
        // ignore close requests while input is focused, so that mouse navigation inside of input field works
        if (document.activeElement !== inputRef.current) {
            dialog.close();
        }
    };

    return {
        pickerProps: {
            open: dialog.isOpen,
            onOpen: dialog.open,
            onClose,
            onError: setError,
        },
        textFieldProps: {
            onClick: onTextFieldClick,
            otherInputRef: inputRef,
        },
        dialog,
        error,
    };
};

/**
 * Only use this date picker with UTC dates or values without time. The component initializes a UTC moment object internally.
 */
const DateRangePicker = ({ value, updateValue, translate, showError, disabled }: IDateRangePickerProps) => {
    // Make sure to be robust to various inputs, e.g. string instead of moment
    const start = value?.start != null ? moment.utc(value?.start) : null;
    const end = value?.end != null ? moment.utc(value?.end) : null;

    // controlled dialog state for start picker so it can be closed when selecting one of the presets
    const startPicker = usePicker();
    const endPicker = usePicker();

    const handleStartChange = (newStartValue: Moment | null) => {
        if (newStartValue?.isValid() === false) {
            return;
        }
        if (newStartValue == null || newStartValue.isSame(start) || !newStartValue.isSame(start, "day")) {
            // keep dialog open if one of the year/month views has been clicked
            startPicker.dialog.close();
        }
        const preparedValue = formatDateRangeForAPI(newStartValue, end);
        updateValue(preparedValue);
    };

    const handleEndChange = (newEndValue: Moment | null) => {
        if (newEndValue?.isValid() === false) {
            return;
        }
        if (newEndValue == null || newEndValue.isSame(end) || !newEndValue.isSame(end, "day")) {
            // keep dialog open if one of the year/month views has been clicked
            endPicker.dialog.close();
        }

        // close dialog and trigger updateValue if date is complete and valid
        endPicker.dialog.close();
        updateValue(formatDateRangeForAPI(start, newEndValue));
    };

    const hasErrorStatus = (value: Moment | null, error?: DateValidationError) => {
        const validRange = isDateBetween(value);
        if (showError === false) {
            // error state intentionally disabled so that initially no error is visible
            return false;
        }
        return error !== null || !value?.isValid() || !validRange;
    };

    const addOneYear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation(); // avoid opening the date picker
        start != null && handleEndChange(start.clone().add(1, "year").subtract(1, "days"));
    };

    const injectPresetButton = (textFieldProps: TextFieldProps) => {
        if (start == null || disabled) {
            return textFieldProps.InputProps;
        }
        return {
            ...textFieldProps.InputProps,
            endAdornment: (
                <>
                    <PresetButton variant="text" onClick={addOneYear}>
                        {`+${translate("1year")}`}
                    </PresetButton>
                    {textFieldProps.InputProps?.endAdornment}
                </>
            ),
        };
    };

    return (
        <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs>
                <DatePicker<Moment>
                    {...startPicker.pickerProps}
                    value={start}
                    onChange={handleStartChange}
                    disabled={disabled}
                    renderInput={(props) => {
                        const hasError = hasErrorStatus(start, startPicker.error);
                        return (
                            <CustomTextField
                                {...props}
                                {...startPicker.textFieldProps}
                                inputProps={{
                                    ...props.inputProps,
                                    placeholder: translate(translationKeys.VDLANG_DATE_PLACEHOLDER),
                                }}
                                fullWidth
                                error={hasError}
                                helperText={hasError ? getHelperText(start, startPicker.error, translate) : undefined}
                            />
                        );
                    }}
                    maxDate={end ?? undefined}
                    minDate={FirstDate}
                    views={["year", "month", "day"]}
                    openTo="day"
                />
            </Grid>
            <Grid item>
                <TrendingFlatIcon color="action" sx={{ mt: 0.75 }} />
            </Grid>
            <Grid item xs>
                <DatePicker<Moment>
                    {...endPicker.pickerProps}
                    value={end}
                    onChange={handleEndChange}
                    disabled={disabled}
                    renderInput={(props) => {
                        const hasError = hasErrorStatus(end, endPicker.error) || endPicker.error === "minDate";
                        return (
                            <CustomTextField
                                {...props}
                                {...endPicker.textFieldProps}
                                inputProps={{
                                    ...props.inputProps,
                                    placeholder: translate(translationKeys.VDLANG_DATE_PLACEHOLDER),
                                }}
                                fullWidth
                                error={hasError}
                                helperText={hasError ? getHelperText(end, endPicker.error, translate) : undefined}
                                InputProps={injectPresetButton(props)}
                            />
                        );
                    }}
                    views={["year", "month", "day"]}
                    openTo="day"
                    minDate={start ?? undefined}
                    maxDate={LastDate}
                />
            </Grid>
        </Grid>
    );
};

export default DateRangePicker;
