import { ListItemIcon, Typography, styled } from "@mui/material";
import { SingleValueProps } from "react-select";
import { dropLegacyRef } from "../../../../lib/utils";
import Tooltip from "../../../Tooltip";
import { Option } from "../types";

const ItemText = styled(Typography, { shouldForwardProp: (prop) => prop !== "isDisabled" && prop !== "reverseDirection" })<{
    isDisabled: boolean;
    reverseDirection?: boolean;
    component: React.ElementType;
}>(({ isDisabled, reverseDirection, theme }) => ({
    ...(isDisabled && {
        color: theme.palette.text.disabled,
    }),
    ...(reverseDirection && {
        direction: "rtl", // enable shortening on left side by reversing direction
    }),
}));

const CenteredListItemIcon = styled(ListItemIcon)({ verticalAlign: "middle" });

const SelectSingleValue = <OptionType extends Option, IsMulti extends boolean>({
    data,
    isDisabled,
    innerProps, // May be null, although react-select typings do not reflect that
    children,
    selectProps,
}: SingleValueProps<OptionType, IsMulti>) => {
    const { IconComponent } = data;
    const icon = IconComponent !== undefined ? <IconComponent /> : data.icon;
    return (
        <Tooltip onlyOverflowing title={typeof children === "string" ? children : ""}>
            <ItemText
                {...innerProps}
                ref={dropLegacyRef(innerProps?.ref)}
                variant="body2"
                color="textPrimary"
                className={innerProps?.className}
                component="div"
                /*
                Due to the structure of React Select no MouseOver event is triggered, when disabled.
                The Workaround is to disable the nowrap, so the item can be read.
                 */
                noWrap={!isDisabled}
                isDisabled={isDisabled}
                reverseDirection={selectProps.ellipsisLeft}
            >
                {icon !== undefined ? <CenteredListItemIcon>{icon}</CenteredListItemIcon> : null}
                {/* Add LTR unicode to force correct content in case of numbers or special characters */}
                {"\u202A"}
                {children}
                {"\u202C"}
            </ItemText>
        </Tooltip>
    );
};

export default SelectSingleValue;
