import { Link as StandardLink, Typography } from "@mui/material";
import { ReactNode } from "react";
import { CellProps } from "react-table";
import RouterLink from "../Link";
import Tooltip from "../Tooltip";

interface ITableLinkCellProps<D extends object = Record<string, unknown>> extends CellProps<D> {
    external?: boolean;
    /**
     * Cell value will be used as link text, falling back to children when the cell value is nullish.
     *
     * Additionally, when the value differs from the provided children, the children will be renderend outside the link element so that
     * additional content can be rendered
     *
     * @type {ReactNode}
     * @memberof ITableLinkCellProps
     */
    children?: ReactNode;
    link?: string;
    className?: string;
}

/**
 * Renders a link targeting the value provided by "link" prop.
 *
 * Uses the router by default, so pages not recognized by the router will result in an error page
 *
 * The external prop disables the router, and renders a regular <a> tag instead.
 *
 * @template D
 * @param {ITableLinkCellProps<D>} {
 *     value,
 *     children,
 *     link = "/",
 *     external = false,
 *     ...textCellProps
 * }
 * @returns
 */
const TableLinkCell = <D extends object = Record<string, unknown>>({
    value,
    children,
    link = "/",
    external = false,
}: ITableLinkCellProps<D>) => {
    const linkLabel = value ?? children;
    // additional children are rendered outside the tooltip scope
    return (
        <div>
            <Tooltip title={linkLabel} onlyOverflowing>
                <Typography noWrap>
                    {external ? <StandardLink href={link}>{linkLabel}</StandardLink> : <RouterLink to={link}>{linkLabel}</RouterLink>}
                </Typography>
            </Tooltip>
            {linkLabel !== children ? children : null}
        </div>
    );
};

export default TableLinkCell;
