import { CurrencyDto } from "api-shared";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
    delocalizeDecimalSeparator,
    formatCurrency,
    localizeDecimalSeparator,
    removeGroupingSeparator,
    removeNoisyCurrencyCharacters,
} from "../lib/currency";
import { Language, translationKeys } from "../translations/main-translations";
import useCurrencyContext from "../view/CurrencyContext";
import { useLanguage } from "./useLanguage";

const CURRENCY_SYMBOL_SEPARATOR = " ";

type SignDisplay = "auto" | "never" | "always" | "exceptZero";

// Allow per-call override of currency for CSV export, which needs to display values in process currency, which may be different for each
// process
export type CurrencyFormatter = (value: string | number, customCurrency?: CurrencyDto | string, signDisplay?: SignDisplay) => string | null;

interface IUseCurrencyProps {
    /**
     * Use this currency instead of the one provided by the context
     *
     * @type {CurrencyDto}
     * @memberof IUseCurrencyProps
     */
    currency?: CurrencyDto | string;

    /**
     * Use this language instead of the user's default one
     *
     * @type {Language}
     * @memberof IUseCurrencyProps
     */
    language?: Language;
}

export interface IUseCurrencyResult {
    currencyIsoCode: string;

    /**
     * Format value with localized separators and the currency code.
     *
     * Depending on the language, the code is added as prefix (e.g. en locale) or suffix (e.g. de locale)
     *
     * @param {(string | number)} value
     * @param {CurrencyDto | string} [customCurrency]
     * @returns
     */
    formatCurrency: CurrencyFormatter;

    /**
     * Print formatted currency with localized separators, but without the currency code
     *
     * @param {(string | number)} value
     * @param {CurrencyDto | string} [customCurrency]
     * @returns {string}
     */
    formatCurrencyNoCode: CurrencyFormatter;

    /**
     * Format value with localized separators and the currency code and shortened values according the the magnitude, e.g.
     * 1_000_000 => 1.0M EUR
     * 1_000 => 1.0K EUR
     * 1 => 1.0 EUR
     *
     * Depending on the language, the code is added as prefix (e.g. en locale) or suffix (e.g. de locale)
     *
     * @param {(string | number)} value
     * @param {CurrencyDto | string} [customCurrency]
     * @returns
     */
    formatCurrencyShort: CurrencyFormatter;

    /**
     * Format value with localized separators and shortened values according the the magnitude, e.g.
     * 1_000_000 => 1.0M
     * 1_000 => 1.0K
     * 1 => 1.0
     *
     * Depending on the language, the code is added as prefix (e.g. en locale) or suffix (e.g. de locale)
     */
    formatCurrencyShortNoCode: CurrencyFormatter;

    /**
     * Convert a currency input to the default format. Removes Noise currency characters, thousand separators and replaces the decimal
     * separator by the standard one: .
     *
     * @param {string} value
     * @returns
     */
    formatCurrencyInput: (value: string) => string;

    /**
     * Format a currency value to be put as value into a CurrencyInput component. Replaces the default decimal separator by the localized
     * version
     *
     * @param {string} value
     */
    sanitizeCurrencyInput: (value: string) => string;
}

const useCurrency = ({ currency: overrideCurrency, language: overrideLanguage }: IUseCurrencyProps = {}): IUseCurrencyResult => {
    const contextCurrency = useCurrencyContext();
    const userLanguage = useLanguage();

    const { t: translate } = useTranslation();

    const currency = overrideCurrency ?? contextCurrency;
    const language = overrideLanguage ?? userLanguage;

    const localFormatCurrency = useCallback(
        (value: string | number, customCurrency?: CurrencyDto | string) => {
            if (value == null) {
                return null;
            }

            const resolvedCurrency = customCurrency ?? currency;

            const valueAsNumber = typeof value === "number" ? value : parseFloat(value);

            if (isNaN(valueAsNumber)) {
                return null;
            }

            // Format with EUR and replace its code with the configured code to avoid crashes because of wrong ISO-4217 codes.
            const formatted = formatCurrency(valueAsNumber, language, "EUR");
            if (typeof resolvedCurrency === "string") {
                return formatted.replace("EUR", resolvedCurrency);
            } else {
                return formatted.replace("EUR", resolvedCurrency.isoCode);
            }
        },
        [currency, language],
    );

    const formatCurrencyNoCode = useCallback(
        (value: string | number) => {
            if (value == null) {
                return null;
            }

            const valueAsNumber = typeof value === "number" ? value : parseFloat(value);

            if (isNaN(valueAsNumber)) {
                return null;
            }

            return formatCurrency(valueAsNumber, language);
        },
        [language],
    );

    const formatCurrencyShort = useCallback(
        (value: string | number, customCurrency?: CurrencyDto | string, signDisplay?: SignDisplay) => {
            if (value == null) {
                return null;
            }

            const valueAsNumber = typeof value === "number" ? value : parseFloat(value);
            const resolvedCurrency = customCurrency ?? contextCurrency;

            if (isNaN(valueAsNumber)) {
                return null;
            }

            let number, magnitude;
            // magnitude checks are inclusive, so that 1_000_000 will also be formatted as "1.00 M" and not as "1,000 K"

            if (valueAsNumber >= 1e12 || valueAsNumber <= -1e12) {
                number = valueAsNumber / 1e12;
                magnitude = translationKeys.VDLANG_NUMBER_MAGNITUDE_TRILLION;
            } else if (valueAsNumber >= 1e9 || valueAsNumber <= -1e9) {
                number = valueAsNumber / 1e9;
                magnitude = translationKeys.VDLANG_NUMBER_MAGNITUDE_BILLION;
            } else if (valueAsNumber >= 1e6 || valueAsNumber <= -1e6) {
                number = valueAsNumber / 1e6;
                magnitude = translationKeys.VDLANG_NUMBER_MAGNITUDE_MILLION;
            } else if (valueAsNumber >= 1e3 || valueAsNumber <= -1e3) {
                number = valueAsNumber / 1e3;
                magnitude = translationKeys.VDLANG_NUMBER_MAGNITUDE_THOUSAND;
            } else {
                number = valueAsNumber;
            }

            // Format with EUR and replace its code with the configured symbol to avoid crashes because of wrong ISO-4217 codes.
            const formatted = Intl.NumberFormat(language, {
                currency: "EUR",
                style: "currency",
                currencyDisplay: "code",
                signDisplay,
            })
                .format(number)
                .replace("EUR", typeof resolvedCurrency !== "string" ? resolvedCurrency.isoCode : resolvedCurrency);

            if (magnitude) {
                const [digits, symbol] = formatted.split(CURRENCY_SYMBOL_SEPARATOR);
                return [digits, translate(magnitude), symbol].join(CURRENCY_SYMBOL_SEPARATOR);
            }

            return formatted;
        },
        [contextCurrency, language, translate],
    );

    const formatCurrencyShortNoCode = useCallback(
        (value: string | number, customCurrency: CurrencyDto | string = "", signDisplay?: SignDisplay) => {
            // set currency iso code to empty string to remove the iso code from the formatted value
            return formatCurrencyShort(value, customCurrency, signDisplay);
        },
        [formatCurrencyShort],
    );

    const sanitizeCurrencyInput = useCallback(
        (value: string) => {
            const cleaned = removeNoisyCurrencyCharacters(value);
            const ungrouped = removeGroupingSeparator(cleaned, language);
            return delocalizeDecimalSeparator(ungrouped, language);
        },
        [language],
    );

    const formatCurrencyInput = useCallback((value: string) => localizeDecimalSeparator(value, language), [language]);

    return {
        currencyIsoCode: contextCurrency.isoCode,
        formatCurrency: localFormatCurrency,
        formatCurrencyNoCode,
        formatCurrencyShort,
        sanitizeCurrencyInput,
        formatCurrencyInput,
        formatCurrencyShortNoCode,
    };
};

export default useCurrency;
