import { validateCurrencyValue } from "api-shared";
import { defaultLanguage } from "../translations/main-translations";

const DECIMAL_SEPARATOR = ".";
const FORMULA_CHARACTER = "=";
const MAX_FRACTION_DIGITS = 2;

const CURRENCY_VALUE_REG_EXP = /([\d.]+)/g;
const NOISY_CURRENCY_INPUT_REG_EXP = /[\s$€]/g;

// Allow regular numbers OR formula characters only if the value starts with an equals sign
const VALID_CURRENCY_INPUT_REG_EXP = /(^-?[0-9,.€$\s]*$)|(^=[0-9,.€$\s+*/()-]*$)/g;

/*
 * Formatting
 */

export function formatCurrency(value: number, locale = defaultLanguage, currencyIsoCode?: string): string {
    const currencyOptions: Partial<Intl.NumberFormatOptions> = currencyIsoCode
        ? {
              style: "currency",
              currency: currencyIsoCode,
              currencyDisplay: "code",
          }
        : {};
    return value.toLocaleString(locale, {
        maximumFractionDigits: MAX_FRACTION_DIGITS,
        minimumFractionDigits: MAX_FRACTION_DIGITS,
        ...currencyOptions,
    });
}

/*
 * Validation
 */

/**
 * Check if a value is a valid currency value:
 * - No grouping separator
 * - Default decimal separator
 * - Maximum number of integer digits
 * - Maximum number of fraction digits
 * @param {string} value - A single currency value
 */
export function isValidCurrencyValue(value: string): boolean {
    const matches = value.match(CURRENCY_VALUE_REG_EXP);

    if (matches === null) {
        return false;
    }

    return matches.map(parseFloat).every(validateCurrencyValue);
}

/**
 * Check if the provided input is a valid currency input:
 * - A potentially noisy currency value
 * - A potentially noisy formula
 * @param {string} input - A string that was read from a currency input
 */
export function isValidCurrencyInput(input: string): boolean {
    return input.match(VALID_CURRENCY_INPUT_REG_EXP) !== null;
}

/**
 * Check if the provided input is a valid currency formula input:
 * - A potentially noisy formula
 * @param formula
 */
export function isValidCurrencyFormulaInput(formula: string): boolean {
    return formula.startsWith(FORMULA_CHARACTER) && formula.match(VALID_CURRENCY_INPUT_REG_EXP) !== null;
}

/*
 * Transformation
 */

/**
 * Remove noisy characters from a currency input like currency symbols and whitespace
 * @param {string} value - A currency value that should be modified
 */
export function removeNoisyCurrencyCharacters(value: string): string {
    return value.replace(NOISY_CURRENCY_INPUT_REG_EXP, "");
}

/**
 * Remove the grouping separator from a currency value (, or . depending on locale)
 * @param {string} value - A currency value that should be modified
 * @param {Language} locale - Optional locale to determine the grouping separator
 */
export function removeGroupingSeparator(value: string, locale = defaultLanguage): string {
    const groupingSeparator = (1000).toLocaleString(locale).substring(1, 2);
    const groupingSeparatorMatcher = new RegExp(`[${groupingSeparator}]`, "g");
    return value.replace(groupingSeparatorMatcher, "");
}

/**
 * Replace the current locale's decimal separator (, or .) with the default decimal separator
 * @param {string} value - A currency value that should be modified
 * @param {Language} locale - Optional locale to determine the decimal separator
 */
export function delocalizeDecimalSeparator(value: string, locale = defaultLanguage): string {
    const decimalSeparator = (0.1).toLocaleString(locale).substring(1, 2);
    const decimalSeparatorRegExp = new RegExp(`[${decimalSeparator}]`, "g");
    return value.replace(decimalSeparatorRegExp, DECIMAL_SEPARATOR);
}

/**
 * Replace the default decimal separator (.) with the current locale's decimal separator
 * @param {string} value - A currency value that should be modified
 * @param {Language} locale - Optional locale to determine the decimal separator
 */
export function localizeDecimalSeparator(value: string, locale = defaultLanguage) {
    const decimalSeparator = (0.1).toLocaleString(locale).substring(1, 2);
    const defaultDecimalSeparatorRegExp = new RegExp(`[${DECIMAL_SEPARATOR}]`, "g");
    return value.replace(defaultDecimalSeparatorRegExp, decimalSeparator);
}
