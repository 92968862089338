import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import ErrorBoundary from "../components/ErrorBoundary";
import LoadingAnimation from "../components/loading/LoadingAnimation";
import { useBackgroundLogout } from "../hooks/useBackgroundTabs";
import { useLanguage } from "../hooks/useLanguage";
import { AppState } from "../infrastructure/store";
import Notifier from "./Notifier";
import Routes from "./Routes";
import SnackbarProvider from "./SnackbarProvider";

function MainContainer({ isBootstrapping }: ConnectedProps<typeof connector>) {
    const language = useLanguage();
    useBackgroundLogout();
    React.useEffect(() => {
        const htmlElement = document.querySelector("html");
        if (htmlElement !== null) {
            htmlElement.lang = language;
        }
    }, [language]);

    return (
        <ErrorBoundary>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <SnackbarProvider>
                    <Notifier />
                    {isBootstrapping ? <LoadingAnimation /> : <Routes />}
                </SnackbarProvider>
            </LocalizationProvider>
        </ErrorBoundary>
    );
}

const mapStateToProps = (state: AppState) => ({
    isBootstrapping: state.isFetching.bootstrap,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(MainContainer);
