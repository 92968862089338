import {
    AttributeOptions,
    AttributeTable,
    AttributeTitle,
    CurrencyDto,
    DecisionResult,
    EffectType,
    FieldDefinitionsDto,
    FieldTypes,
    GateTaskConfigDto,
    IdeaAttributeDto,
    MeasureAttributeDto,
    MeasureFieldNames,
    MeasureStatus,
    MethodSegment,
    TranslationType,
    UserDto,
    UserStatus,
    DiscardReasons as discardReasons,
} from "api-shared";
import { TFunction } from "i18next";
import { isNumber, isString } from "lodash";
import { translationKeys } from "../translations/main-translations";
import { RawOption } from "./field-options";
import { formatUser } from "./formatters";

export const USER_STATUS_FIELD_NAME = "USER_STATUS_FIELD";

export type TranslatableConstant = UserStatus | MethodSegment | DecisionResult | MeasureStatus;

export type LabelAccessor = (option: unknown, translate: TFunction) => string;

export interface FieldOptions extends Omit<AttributeOptions, "name"> {
    // Extend name with LabelAccessor
    name?: AttributeOptions["name"] | LabelAccessor;
}

export interface Field extends Omit<MeasureAttributeDto | IdeaAttributeDto, "id" | "clientId" | "createdAt" | "updatedAt" | "options"> {
    options: FieldOptions | null;
    filteredValues?: number[];
    ignoreResolvingErrors?: boolean;
}

export interface MeasureField extends Field {
    id: number;
    isVisible: boolean;
    value: FieldValue;
    options: Field["options"];
    gateTaskConfigId?: number | null;
}

export type FieldValue = any;
export interface FilledField extends Field {
    value: FieldValue;
    formula?: string | null;
}

class SyntheticField implements Field {
    id: number;

    options: FieldOptions;

    translate: TranslationType;

    title: string;

    type: string;

    mandatory: boolean;

    quantity: number | null;

    clientId: number | null;

    tableName: string | null;

    order: number | null;

    isCreatable: boolean | null;

    createdAt: Date;

    updatedAt: Date;

    constructor(field: Partial<Field>) {
        this.id = -1;
        this.options = {};
        this.translate = TranslationType.Map;
        this.title = "";
        this.type = "";
        this.mandatory = true;
        this.quantity = null;
        this.clientId = null;
        this.tableName = null;
        this.order = null;
        this.isCreatable = null;
        this.createdAt = new Date();
        this.updatedAt = new Date();
        Object.assign(this, field);
    }
}

const valueLeverField = new SyntheticField({
    title: "valueLever",
    options: {
        name: "alias",
    },
    translate: TranslationType.MapAndTranslate,
    tableName: "value-levers",
});

const userField = new SyntheticField({
    options: {
        name: ((user: UserDto, translate: TFunction) => formatUser(user, { translate })) as LabelAccessor,
    },
    translate: TranslationType.Map,
    tableName: "users",
});

const favoriteField = new SyntheticField({
    title: "boolean",
    options: {
        values: [
            { id: 1, name: translationKeys.VDLANG_FIELD_FAVORITE_IS_FAVORITE },
            { id: 0, name: translationKeys.VDLANG_FIELD_FAVORITE_IS_NOT_FAVORITE },
        ],
        name: "name",
    },
    translate: TranslationType.MapAndTranslate,
    tableName: null,
    type: FieldTypes.Boolean,
});

const discardReasonField = new SyntheticField({
    title: AttributeTitle.DiscardReason,
    options: {
        values: discardReasons.map(({ id, de, en }) => ({
            id,
            nameDe: de,
            nameEn: en,
        })),
    },
    translate: TranslationType.MapToLangProperty,
});

const currencyField = new SyntheticField({
    title: MeasureFieldNames.Currencies,
    options: {
        name: ((currency: CurrencyDto) => currency.isoCode) as LabelAccessor,
    },
    translate: TranslationType.Map,
    tableName: "currencies",
});

const measureConfigField = new SyntheticField({
    title: "measureConfigId",
    options: {
        name: "name",
    },
    translate: TranslationType.MapAndTranslate,
    tableName: "measure_configs",
});

const gateTaskConfigField = new SyntheticField({
    title: "currentGateTaskConfigId",
    options: {
        name: ((gtc: GateTaskConfigDto & RawOption, translate: TFunction) =>
            translate(gtc.name) + (gtc.processName && gtc.isDuplicate ? " (" + translate(gtc.processName) + ")" : "")) as LabelAccessor,
        resolveDuplicates: true,
    },
    translate: TranslationType.Map,
    tableName: "gate_task_configs",
});

const groupGroupsWithAccessField = new SyntheticField({
    title: "groupsWithAccess",
    options: {
        name: "name",
    },
    translate: TranslationType.Map,
    tableName: AttributeTable.GroupsWithAccess,
});

const constantsToTranslations: Record<string, Record<string | number, string>> = {
    [MeasureFieldNames.Status]: {
        [MeasureStatus.STATUS_DISCARDED]: "measure_status_0",
        [MeasureStatus.STATUS_OPEN]: "measure_status_1",
        [MeasureStatus.STATUS_CLOSED]: "measure_status_2",
    },
    [MeasureFieldNames.EffectType]: {
        [EffectType.Savings]: "effect_type_1",
        [EffectType.ChangeoverCosts]: "effect_type_2",
    },
    [USER_STATUS_FIELD_NAME]: {
        [UserStatus.STATUS_ACTIVE]: "USER_STATUS_ACTIVE",
        [UserStatus.STATUS_INVITED]: "USER_STATUS_INVITED",
        [UserStatus.STATUS_INACTIVE]: "USER_STATUS_INACTIVE",
        [UserStatus.STATUS_REGISTERED]: "USER_STATUS_REGISTERED",
        [UserStatus.STATUS_DELETED]: "USER_STATUS_DELETED",
    },
    [MeasureFieldNames.MethodSegment]: {
        [MethodSegment.CUSTOM]: "customer specific method",
        [MethodSegment.PRODUCT]: "product measure",
        [MethodSegment.PURCHASING]: "purchasing measure",
    },
};

/**
 * Map one of the supported constants (@see TanslatableConstant) to its translation key.
 *
 * @param {string} fieldName
 * @param {TranslatableConstant} value
 * @returns {(string | null)}
 */
export const mapConstantsToTranslations = (fieldName: string, value: string | number): string | null => {
    const field = constantsToTranslations[fieldName];
    return field != null ? field[value] : null;
};

/**
 * Check if the field can be translated as a constant.
 *
 * @param {string} fieldName
 */
export const isTranslateableConstant = (fieldName: string) => fieldName in constantsToTranslations;

/**
 * Get field definition by name.
 *
 * Resolves the field using the given field definitions. Then, the associated measure attribute is resolved using the given measure attributes.
 * If the attribute cannot be resolved, the Synthetic fields are checked. Those are:
 * - Company
 * - Address
 * - DiscardReason
 * - User
 *
 * @param {MeasureAttributeDto[]} measureAttributes
 * @param {FieldDefinitionsDto} fieldDefinitions
 * @param {string} fieldName
 * @returns {(Field | null)}
 */
export const findField = (
    measureAttributes: MeasureAttributeDto[],
    fieldDefinitions: FieldDefinitionsDto,
    fieldName: string,
): Field | null => {
    if (!Array.isArray(measureAttributes)) {
        return null;
    }

    // name contains a field name
    const field = fieldDefinitions[fieldName];
    if (field === undefined) {
        return null;
    }
    const { attributeName } = field;
    let attribute: Field | undefined = measureAttributes.find((ma) => ma.title === attributeName);

    // valueLever is not available as attribute anymore, but needed in some places
    if (attribute === undefined && fieldName === MeasureFieldNames.ValueLever) {
        attribute = valueLeverField;
    }
    // override discard reason options with values from api-shared
    if (fieldName === AttributeTitle.DiscardReason) {
        attribute = discardReasonField;
    }

    if (fieldName === MeasureFieldNames.Currencies) {
        attribute = currencyField;
    }

    if (fieldName === MeasureFieldNames.MeasureConfigId) {
        attribute = measureConfigField;
    }

    if (fieldName === MeasureFieldNames.CurrentGateTaskConfigId) {
        attribute = gateTaskConfigField;
    }

    if (fieldName === MeasureFieldNames.Favorite) {
        attribute = favoriteField;
    }

    if (field.type === FieldTypes.User || field.type === FieldTypes.Users) {
        attribute = {
            ...userField,
            title: field.name,
        };
    }

    if (fieldName === "groupsWithAccess") {
        attribute = groupGroupsWithAccessField;
    }

    if (isTranslateableConstant(fieldName)) {
        // constants will be resolved differently
        return null;
    }

    if (attribute === undefined) {
        return null;
    }

    // Return clone to avoid issues with modifications by caller
    return { ...attribute };
};

/**
 * Extract value from a field. Fallback to null, if field cannot be found.
 *
 * @param {string} fieldName
 * @param {FilledField[]} fields
 * @returns {(FieldValue)}
 */
export const getFieldValue = (fieldName: string, fields: FilledField[]): FieldValue => {
    const field = fields.find((f: FilledField) => f.title === fieldName);
    return field !== undefined ? field.value : null;
};

/**
 * Extract value as number. (@see getFieldValue)
 *
 * @param {string} fieldName
 * @param {FilledField[]} fields
 * @returns {(number | null)}
 */
export const getFieldValueAsNumber = (fieldName: string, fields: FilledField[]): number | null => {
    const fieldValue = getFieldValue(fieldName, fields);
    return fieldValue != null ? +fieldValue : fieldValue;
};

/**
 * Parse a field value and return its content as an array of ids, or just a number, if value is not a string.
 *
 * Falls back to an empty array, if value is null
 *
 * @param {FieldValue} value
 * @returns {(number[] | number)}
 */
export const getFieldValueAsIds = (value: FieldValue): number[] | number => {
    // fields in Opps can already be arrays nothing to do here
    if (Array.isArray(value)) {
        return value.map((v) => Number(v));
    }
    if (typeof value === "string" && value.length > 0) {
        return value.split(",").map((v) => Number(v));
    }
    if (value != null) {
        return Number(value);
    }
    return [];
};

/**
 * Safely remove depends value from field options by copying both field and options object.
 *
 * @export
 * @template FieldType
 * @param {FieldType} field
 * @returns {FieldType} a reference to a new, cleaned field object
 */
export function removeDependsFromField<FieldType extends Field>(field: FieldType): FieldType {
    if (field.options === null) {
        return field;
    }

    if (field.options.depends != null && field.options.dependsValue == null) {
        // drop dependencies of parent value because not parent value is available
        // except when a parentValue is provided manually via dependsValue
        const { depends, ...otherOptions } = field.options; // safely remove depends from options
        return { ...field, options: otherOptions };
    }
    return field;
}

export const isMandatoryAndNotFilled = (field: FilledField) =>
    field.mandatory &&
    (field.value == null || field.value === "" || (field.type === "date_range" && (field.value.start == null || field.value.end == null)));

export const hasFormulaError = (field: FilledField) => field.value == null && field.formula != null;

export const getFieldsForGate = (fields: Record<string, any>, gate: number, onlyTotal = false) =>
    Object.entries(fields).reduce(
        (acc, [key, field]) => {
            const { total: fieldTotal, gate: fieldGate } = field;
            if (fieldGate === gate && !!fieldTotal === onlyTotal) {
                acc[key] = field;
            }
            return acc;
        },
        {} as Record<string, any>,
    );

export const mergeFieldValues = (oldValue: unknown, newValue: unknown, isMulti: boolean): string => {
    const finalValue = String(newValue);
    if (!isMulti) {
        // replace old value for non-multi fields
        return finalValue;
    }

    // keep old value, if it is a multi field and append new value at the end
    if (Array.isArray(oldValue)) {
        return [...oldValue, newValue].join(",");
    }
    if (isNumber(oldValue) || isString(oldValue)) {
        return oldValue === "" ? String(newValue) : [oldValue.toString(), newValue].join(",");
    }
    // no compatible old value that could be appended to, replace with new value
    return finalValue;
};
