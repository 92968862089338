import { FeatureFlags, UserDto } from "api-shared";
import { matchPath } from "react-router-dom";
import { Language, translationKeys } from "../translations/main-translations";

export const RouteFor = {
    feed: {
        view: "/feed",
        withIdParam: "/feed/:feedEntryId",
    },
    dashboard: {
        view: "/dashboards",
        withIdParam: "/dashboards/:id",
        forId: (id: number) => `/dashboards/${id}`,
    },
    measures: {
        view: "/measures",
        desk: "/measures/desk",
        grid: "/measures/grid",
    },
    measure: {
        withIdParam: "/measure/:id",
        forId: (id: number) => `/measure/${id}`,
        detailViewTab: {
            calculation: "calculation",
            activities: "activities",
            comments: "comments",
            documents: "documents",
            history: "history",
            feedHistory: "feed",
            suggestions: "suggestions",
        },
    },
    opportunities: { view: "/opportunities", withIdParam: "/opportunities/:ideaId", forId: (id: number) => `/opportunities/${id}` },
    activities: "/tasks",
    activity: (id: number) => `/tasks/${id}`,
    method: {
        view: "/methods",
        withCodeParam: "/methods/:code",
        forCode: (code: string) => `/methods/${code}`,
    },
    admin: {
        view: "/admin",
        organization: "/admin/organization",
        companies: "/admin/companies",
        treeNodes: "/admin/tree-nodes",
        users: "/admin/users",
        groups: "/admin/groups",
        permissions: "/admin/permissions",
        measures: "/admin/measures",
        opportunities: "/admin/opportunities",
        domains: "/admin/domains",
        signon: "/admin/signon",
        currencies: "/admin/currencies",
        departments: "/admin/departments",
        suppliers: "/admin/suppliers",
    },
    superAdmin: {
        view: "/superadmin",
        fieldRelations: "/superadmin/field-relations",
        fieldRelationsIdeas: "/superadmin/field-relations/ideas",
        clients: "/superadmin/clients",
        translations: "/superadmin/translations",
        treeNodes: "/superadmin/tree-nodes",
        costLevers: "/superadmin/cost-levers",
        domains: "/superadmin/domains",
        customFields: "/superadmin/custom-fields",
        suppliers: "/superadmin/suppliers",
    },
    user: {
        view: "/user",
        settings: "/user/profile",
        login: "/users/login",
        twoFactorAuthenticationSetup: "/users/two-factor-authentication-setup",
        password: "/user/password",
        tfa: "/user/two-factor-authentication",
        passwordReset: "/users/password-reset",
        notifications: "/user/notifications",
        integrations: "/user/integrations",
    },
    labs: "/labs",
    dataImport: "/data-import",
    protected: {
        view: "/protected",
        passwordReset: "/protected/password-reset",
        invite: "/protected/invite",
    },
    deprecated: {
        pipeline: "/pipeline",
        grid: "/grid",
        news: "/news",
        dashboard: "/dashboard",
        suppliers: "/suppliers",
    },
} as const;

export const ExternalRoutes = {
    privacyPolicy: {
        [Language.EN]: "/docs/imprint-privacy-policy-en.htm",
        [Language.DE]: "/docs/imprint-privacy-policy-de.htm",
    },
    support: "mailto:support@valuedesk.de",
};

export type NavPermissions = {
    hasIdeaAccess: boolean;
    hasActivityAccess: boolean;
    hasFeedAccess: boolean;
    hasDashboardAccess: boolean;
    hasProcessAccess: boolean;
    hasMethodAccess: boolean;
};

class NavItem {
    constructor(
        public route: string,
        public label: string,
        public showMobile = false,
        public external = false,
    ) {}
}

// NavItems should point to an index route, e.g. /admin instead of /admin/organization so that NavLink can be properly shown the routes active status
export const NavItems = {
    News: new NavItem(RouteFor.feed.view, translationKeys.VDLANG_SECTIONS_NEWS, true),
    Dashboards: new NavItem(RouteFor.dashboard.view, translationKeys.VDLANG_SECTIONS_DASHBOARD, false),
    measures: new NavItem(RouteFor.measures.view, translationKeys.VDLANG_SECTIONS_PROCESSES),
    Activities: new NavItem(RouteFor.activities, translationKeys.VDLANG_MY_ACTIVITIES_TITLE, true),
    Methods: new NavItem(RouteFor.method.view, translationKeys.VDLANG_METHODS_NAVBAR_ITEM_LABEL),
    Opportunities: new NavItem(RouteFor.opportunities.view, translationKeys.VDLANG_IDEAS_NAVBAR_ITEM_LABEL, true),
    Login: new NavItem(RouteFor.user.login, translationKeys.VDLANG_SECTIONS_LOGIN),
    Settings: new NavItem(RouteFor.user.view, translationKeys.VDLANG_SECTIONS_SETTINGS, true),
    AdminSection: new NavItem(RouteFor.admin.view, translationKeys.VDLANG_SECTIONS_ADMIN, true),
    SuperAdminSection: new NavItem(RouteFor.superAdmin.view, translationKeys.VDLANG_SECTIONS_SUPER_ADMIN, false),
    PrivacyPolicyDe: new NavItem(ExternalRoutes.privacyPolicy[Language.DE], translationKeys.VDLANG_PRIVACY_POLICY, true, true),
    PrivacyPolicyEn: new NavItem(ExternalRoutes.privacyPolicy[Language.EN], translationKeys.VDLANG_PRIVACY_POLICY, true, true),
    ExcelImport: new NavItem(RouteFor.dataImport, translationKeys.VDLANG_DATA_IMPORT, false),
};

/**
 * Get NavbarItems that are visible on desktop screen dimensions. The set differs depending on the client's enabled features and the users
 * permissions.
 *
 * @export
 * @param {{ [key: string]: number }} features Set of features of the current client
 * @returns {NavItem[]} The visible NavItems
 */
export function getDesktopNavItems(features: { [key: string]: number }, navPermissions: NavPermissions): NavItem[] {
    const tabs = [];
    if (navPermissions.hasFeedAccess) {
        tabs.push(NavItems.News);
    }
    if (navPermissions.hasDashboardAccess) {
        tabs.push(NavItems.Dashboards);
    }
    if (features[FeatureFlags.FEATURE_IDEA_SECTION] === 1 && navPermissions.hasIdeaAccess) {
        tabs.push(NavItems.Opportunities);
    }
    if (navPermissions.hasProcessAccess) {
        tabs.push(NavItems.measures);
    }
    if (navPermissions.hasActivityAccess) {
        tabs.push(NavItems.Activities);
    }
    if (features[FeatureFlags.FEATURE_METHOD_SECTION] === 1 && navPermissions.hasMethodAccess) {
        tabs.push(NavItems.Methods);
    }

    return tabs;
}

/**
 * Get all NavItems that should be shown on mobile devices. Accounts for the client's enabled features.
 *
 * @export
 * @param {{ [key: string]: number }} features Set of features of the current client
 * @returns {NavItem[]} The visible NavItems
 */
export function getMobileNavItems(features: { [key: string]: number }, navPermissions: NavPermissions): NavItem[] {
    return getDesktopNavItems(features, navPermissions).filter((tab) => tab.showMobile);
}

/**
 * Get visible NavItems that are part of the user's menu. Accounts for the user's permissions (admin, ...).
 */
export function getUserMenuNavItems(
    language: Language,
    hasSuperAdminPermission: boolean,
    hasAdminPermission: boolean,
    hasExcelImportPermission: boolean,
): NavItem[] {
    const privacyPolicy = language === Language.DE ? NavItems.PrivacyPolicyDe : NavItems.PrivacyPolicyEn;
    const entries = [NavItems.Settings, privacyPolicy];
    if (hasSuperAdminPermission) {
        entries.splice(1, 0, NavItems.SuperAdminSection);
    }
    if (hasAdminPermission) {
        entries.splice(1, 0, NavItems.AdminSection);
    }
    if (hasExcelImportPermission) {
        entries.splice(1, 0, NavItems.ExcelImport);
    }
    return entries;
}

/**
 * Make a pathname match also nested routes by appending "/*" at the end.
 *
 * @param {string} pathname
 */
export const withNestedRoutes = (pathname: string) => `${pathname}${pathname.endsWith("/") ? "" : "/"}*`;

const routesWithoutNavbar = [
    RouteFor.user.login,
    RouteFor.user.twoFactorAuthenticationSetup,
    RouteFor.user.passwordReset,
    RouteFor.protected.passwordReset,
    RouteFor.protected.invite,
];

const unauthenticatedRoutes = [withNestedRoutes(RouteFor.protected.view), ...routesWithoutNavbar];

/**
 * Check if the routes matches any of the given routes, using the same matching algorithm as the routing algorithm by react-router
 *
 * Works only for absolute paths, e.g. isMatchingRoute(["/activities"], "/measure/33/activities") will return false
 *
 * @param {string[]} routes
 * @param {string} currentRoute
 * @returns {boolean}
 */
const isMatchingRoute = (routes: string[], currentRoute: string): boolean => routes.some((path) => matchPath(path, currentRoute));

/**
 * Check if given route is one of the routes that is accessible for user that are not logged in, e.g. PasswordReset, LoginPage, ...
 *
 * @param {string} pathname
 * @returns {boolean}
 */
export const isUnauthenticatedRoute = (pathname: string): boolean => isMatchingRoute(unauthenticatedRoutes, pathname);

/**
 * Check if the navbar should be displayed on the current route, e.g. LoginPage
 *
 * @param {string} pathname
 * @returns {boolean}
 */
export const isRouteWithoutNavbar = (pathname: string): boolean => isMatchingRoute(routesWithoutNavbar, pathname);

/**
 * Get first visible Nav Item
 *
 * @param {UserPermissions[] | null} currentUser
 * @param {[x: string]: number} features
 * @param {boolean} isDesktop
 * @returns {string}
 */
export function getFirstPageRoute(
    currentUser: UserDto | null,
    features: { [x: string]: number },
    isDesktop: boolean,
    navPermisions: NavPermissions,
) {
    if (currentUser !== null) {
        const navItems = isDesktop ? getDesktopNavItems(features, navPermisions) : getMobileNavItems(features, navPermisions);
        return navItems.length > 0 ? navItems[0].route : RouteFor.user.settings;
    }
    return RouteFor.user.settings;
}
