import { AclNamespaces, AclPermissions, FeatureFlags } from "api-shared";
import React, { Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Authorization from "../components/Authorization";
import FeatureFlag from "../components/FeatureFlag";
import LoadingAnimation from "../components/loading/LoadingAnimation";
import { useDefaultCurrency } from "../domain/currencies";
import { RouteFor, withNestedRoutes } from "../lib/routes";
import { CurrencyContextProvider } from "./CurrencyContext";
import RootRedirect from "./RootRedirect";
import PageNotFound from "./error/PageNotFound";
import FeedView from "./feed/FeedView";
import NavbarDecorator from "./navbar/NavbarDecorator";
import ActivitiesView from "./tasks/ActivitiesView";
import UserProfile from "./user/UserProfile";

const Measure = React.lazy(() => import("./measure/Measure"));

const SearchConfigProvider = React.lazy(() => import("./measures/SearchConfigProvider"));
const AdminView = React.lazy(() => import("./admin/AdminView"));
const SuperAdmin = React.lazy(() => import("./superadmin/SuperAdmin"));
const MethodsView = React.lazy(() => import("./methods/MethodsView"));
const DashboardsView = React.lazy(() => import("./dashboards/DashboardsView"));
const IdeasView = React.lazy(() => import("./ideas/IdeasView"));
const LabsView = React.lazy(() => import("./labs/LabsView"));
const DataImportView = React.lazy(() => import("./data-import/DataImportView"));

const AuthenticatedRoutes = () => {
    const clientDefaultCurrency = useDefaultCurrency();
    return (
        <CurrencyContextProvider currency={clientDefaultCurrency}>
            <NavbarDecorator>
                <Suspense fallback={<LoadingAnimation />}>
                    <Routes>
                        <Route path={RouteFor.deprecated.pipeline} element={<Navigate replace to={RouteFor.measures.desk} />} />
                        <Route path={RouteFor.deprecated.grid} element={<Navigate replace to={RouteFor.measures.grid} />} />
                        <Route
                            path={RouteFor.feed.view}
                            element={
                                <Authorization namespace={AclNamespaces.Feed} fallbackComponent={PageNotFound}>
                                    <Outlet />
                                </Authorization>
                            }
                        >
                            <Route index element={<FeedView />} />
                            <Route path=":feedEntryId" element={<FeedView />} />
                        </Route>
                        <Route path={withNestedRoutes(RouteFor.measure.withIdParam)} element={<Measure />} />
                        <Route
                            path={withNestedRoutes(RouteFor.measures.view)}
                            element={
                                <Authorization namespace={AclNamespaces.Process} fallbackComponent={PageNotFound}>
                                    <SearchConfigProvider />
                                </Authorization>
                            }
                        />
                        <Route
                            path={withNestedRoutes(RouteFor.activities)}
                            element={
                                <Authorization namespace={AclNamespaces.Activity} fallbackComponent={PageNotFound}>
                                    <ActivitiesView />
                                </Authorization>
                            }
                        />
                        <Route
                            path={withNestedRoutes(RouteFor.method.view)}
                            element={
                                <Authorization namespace={AclNamespaces.Method} fallbackComponent={PageNotFound}>
                                    <FeatureFlag feature={FeatureFlags.FEATURE_METHOD_SECTION} fallbackComponent={PageNotFound}>
                                        <MethodsView />
                                    </FeatureFlag>
                                </Authorization>
                            }
                        />
                        <Route path={withNestedRoutes(RouteFor.user.view)} element={<UserProfile />} />
                        <Route
                            path={withNestedRoutes(RouteFor.admin.view)}
                            element={
                                <Authorization namespace={AclNamespaces.Admin} fallbackComponent={PageNotFound}>
                                    <AdminView />
                                </Authorization>
                            }
                        />
                        <Route
                            path={withNestedRoutes(RouteFor.superAdmin.view)}
                            element={
                                <Authorization namespace={AclNamespaces.SuperAdmin} fallbackComponent={PageNotFound}>
                                    <SuperAdmin />
                                </Authorization>
                            }
                        />
                        <Route
                            path={RouteFor.dashboard.view}
                            element={
                                <Authorization namespace={AclNamespaces.Dashboard} fallbackComponent={PageNotFound}>
                                    <Outlet />
                                </Authorization>
                            }
                        >
                            <Route index element={<DashboardsView />} />
                            <Route path=":id" element={<DashboardsView />} />
                        </Route>
                        <Route
                            path={RouteFor.opportunities.view}
                            element={
                                <Authorization namespace={AclNamespaces.Idea} fallbackComponent={PageNotFound}>
                                    <FeatureFlag feature={FeatureFlags.FEATURE_IDEA_SECTION} fallbackComponent={PageNotFound}>
                                        <Outlet />
                                    </FeatureFlag>
                                </Authorization>
                            }
                        >
                            <Route index element={<IdeasView />} />
                            <Route path=":ideaId" element={<IdeasView />} />
                        </Route>
                        <Route
                            path={RouteFor.labs}
                            element={
                                <FeatureFlag feature={FeatureFlags.FEATURE_EXPERIMENTAL} fallbackComponent={PageNotFound}>
                                    <LabsView />
                                </FeatureFlag>
                            }
                        />
                        <Route
                            path={withNestedRoutes(RouteFor.dataImport)}
                            element={
                                <Authorization
                                    namespace={AclNamespaces.Api}
                                    permission={AclPermissions.Import}
                                    fallbackComponent={PageNotFound}
                                >
                                    <FeatureFlag feature={FeatureFlags.FEATURE_EXCEL_IMPORT} fallbackComponent={PageNotFound}>
                                        <DataImportView />
                                    </FeatureFlag>
                                </Authorization>
                            }
                        />
                        <Route path={RouteFor.user.login} element={<Navigate replace to={RouteFor.feed.view} />} />
                        <Route path="/" element={<RootRedirect />} />
                        <Route path={RouteFor.deprecated.news} element={<Navigate replace to={RouteFor.feed.view} />} />
                        <Route path={RouteFor.deprecated.dashboard} element={<Navigate replace to={RouteFor.feed.view} />} />
                        <Route path={RouteFor.deprecated.suppliers} element={<Navigate replace to={RouteFor.admin.suppliers} />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </Suspense>
            </NavbarDecorator>
        </CurrencyContextProvider>
    );
};

export default AuthenticatedRoutes;
